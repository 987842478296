<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-user-circle"></i> ข้อมูลรายละเอียดการสั่งซื้อ </h2>
            </b-col>
          </b-row>
        </div>
        <div class="card-body">
          <div class="pb-4 text-right"></div>
          <div>
            <div class="card custom-card">
              <div class="card-body">

                <div class="row">
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="form-label" style="font-weight: bold;">รหัสสั่งซื้อ :</label>
                      <p>{{item.code ? item.code : '-'}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="form-label" style="font-weight: bold;">วันที่สั่งซื้อ :</label>
                      <p>{{item.date_order ? item.date_order : '-'}}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="form-label" style="font-weight: bold;">หมายเหตุ :</label>
                      <p>{{item.remark ? item.remark : '-'}}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <b-card no-body>
                    <b-tabs card>
                      <b-tab title="ข้อมูลสินค้า" active>
                        <b-card-text>
                          <div class="row">
                            <div class="col-12">
                              <div class="table-responsive padding_top_20">
                                <table class="table align-middle table-bordered">
                                  <thead>
                                    <tr class="text-center" >
                                      <th>ลำดับ</th>
                                      <th>รหัสสินค้า</th>
                                      <th>รายการ</th>
                                      <th>ราคาขาย</th>
                                      <th>ราคารวม</th>
                                      <th>อัพเซลล์</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(data, index) in item.products">
                                      <tr :key="`list_order_view_product_${index}`">
                                        <td class="text-center">
                                          {{index+1}}
                                        </td>
                                        <td class= "text-center">
                                          {{data.product_code}}
                                        </td>
                                        <td class="text-center">{{data.product_name}}</td>
                                        <td class="text-right">{{data.price | number-decimal-format}}</td>
                                        <td class="text-right">
                                          {{data.price | number-decimal-format}}
                                        </td>
                                        <td class="text-center">{{StringUtils.getTextUpsaleStatus(data.upsale).text}}</td>
                                      </tr>
                                      <tr v-for="(sub, subindex) in data.products" :key="`list_order_view_child_product_${subindex}`">
                                        <td class="text-center"></td>
                                        <td class= "text-center">
                                          {{sub.product_code}}
                                        </td>
                                        <td class="text-center">{{sub.product_name}}</td>
                                        <td class="text-right">{{sub.price | number-decimal-format}}</td>
                                        <td class="text-right">
                                          {{data.price | number-decimal-format}}
                                        </td>
                                        <td class="text-center">{{StringUtils.getTextUpsaleStatus(sub.upsale).text}}</td>
                                      </tr>
                                    </template>
                                    <template v-if="item.products.length === 0">
                                      <tr class="text-center">
                                        <td colspan="6">ไม่มีข้อมูล</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                                <div  class="col-12 text-right">
                                  <p>
                                    รวม : {{item.total}}
                                  </p>
                                  <p>
                                    ค่าส่ง : {{item.service}}
                                  </p>
                                  <p>
                                    รวมทั้งสิ้น : {{item.total}}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="ข้อมูลร้านค้า">
                        <b-card-text>

                          <div class="row">
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ชื่อ :</label>
                                <p>{{item.store&&item.store.name ? item.store.name : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">เว็บไซต์ :</label>
                                <p>{{item.store&&item.store.website ? item.store.website : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">เบอร์โทรศัพท์ :</label>
                                <p>{{item.store&&item.store.phone ? item.store.phone : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">อีเมล :</label>
                                <p>{{item.store&&item.store.email ? item.store.email : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ที่อยู่ :</label>
                                <p>{{item.store&&item.store.address ? item.store.address : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ชื่อที่อยู่สำหรับใบกำกับภาษี :</label>
                                <p>{{item.store&&item.store.name_address ? item.store.name_address : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">เลขประจำตัวผู้เสียภาษี :</label>
                                <p>{{item.store&&item.store.tax_id ? item.store.tax_id : '-'}}</p>
                              </div>
                            </div>

                            <div class="col-12">
                              <hr class="my-4">
                            </div>
                            <div class="col-12">
                              <h6 class="heading-small text-muted mb-4">Social Media</h6>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">เฟซบุ๊ค :</label>
                                <p>{{item.store&&item.store.facebook ? item.store.facebook : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ไลน์ :</label>
                                <p>{{item.store&&item.store.line ? item.store.line : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">อินสตาแกรม :</label>
                                <p>{{item.store&&item.store.instagram ? item.store.instagram : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ทวิตเตอร์ :</label>
                                <p>{{item.store&&item.store.twitter ? item.store.twitter : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ยูทูป :</label>
                                <p>{{item.store&&item.store.youtube ? item.store.youtube : '-'}}</p>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="ข้อมูลช่องทาง">
                        <b-card-text>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ชื่อ :</label>
                                <p>{{item.channel&&item.channel.name ? item.channel.name : '-'}}</p>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="ข้อมูลลูกค้า">
                        <b-card-text>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ชื่อผู้ติดต่อ :</label>
                                <p>{{item.customer&&item.customer.contact ? item.customer.contact : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">เบอร์โทรศัพท์ :</label>
                                <p>{{item.customer&&item.customer.phone ? item.customer.phone : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">อีเมล :</label>
                                <p>{{item.customer&&item.customer.email ? item.customer.email : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ไลน์ :</label>
                                <p>{{item.customer&&item.customer.line ? item.customer.line : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ที่อยู่จัดส่ง :</label>
                                <p>{{item.customer&&item.customer.address ? item.customer.address : '-'}}</p>
                              </div>
                            </div>

                            <div class="col-12">
                              <hr class="my-4">
                            </div>
                            <div class="col-12">
                              <h6 class="heading-small text-muted mb-4">ข้อมูลออกใบกำกับภาษี/ใบเสร็จรับเงิน</h6>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ชื่อ :</label>
                                <p>{{item.customer&&item.customer.name ? item.customer.name : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ชื่อบริษัทตามใบเสร็จรับเงิน :</label>
                                <p>{{item.customer&&item.customer.company_name ? item.customer.company_name : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">หมายเลขผู้เสียภาษี :</label>
                                <p>{{item.customer&&item.customer.tax_id ? item.customer.tax_id : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">เบอร์โทรศัพท์ :</label>
                                <p>{{item.customer&&item.customer.billing_phone ? item.customer.billing_phone : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ที่อยู่ตามใบกำกับภาษี/ใบเสร็จรับเงิน :</label>
                                <p>{{item.customer&&item.customer.billing_address ? item.customer.billing_address : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">หมายเหตุ :</label>
                                <p>{{item.customer&&item.customer.remark ? item.customer.remark : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-12">
                              <hr class="my-4">
                            </div>
                            <div class="col-12">
                              <h6 class="heading-small text-muted mb-4">ข้อมูลเครดิตลูกค้า</h6>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ประเภทลูกค้า :</label>
                                <p>{{item.customer&&item.customer.customer_type ? StringUtils.getTextCustomerType(item.customer.customer_type).text : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4" v-if="item.customer.customer_type == 1">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">สถานะเครดิต :</label>
                                <p>{{item.customer&&item.customer.credit_status ? StringUtils.getTextCustomerCreditStatus(item.customer.credit_status).text : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4" v-if="item.customer.customer_type == 1">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">จำนวนเครดิตที่อนุญาต :</label>
                                <p>{{item.customer&&item.customer.credit_line ? item.customer.credit_line : '-'}}</p>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="ข้อมูลซัพพลายเออร์">
                        <b-card-text>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ชื่อซัพพลายเออร์/ชื่อบริษัท/ชื่อร้าน :</label>
                                <p>{{item.supplier&&item.supplier.name ? item.supplier.name : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">หมายเลขผู้เสีภาษี :</label>
                                <p>{{item.supplier&&item.supplier.tax_id ? item.supplier.tax_id : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">เกรด :</label>
                                <p>{{item.supplier&&item.supplier.tier ? item.supplier.tier : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ที่อยู่ตามใบเสร็จรับเงิน :</label>
                                <p>{{item.supplier&&item.supplier.tax_address ? item.supplier.tax_address : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ชื่อผู้ติดต่อ :</label>
                                <p>{{item.supplier&&item.supplier.contact ? item.supplier.contact : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">เบอร์โทรศัพท์ :</label>
                                <p>{{item.supplier&&item.supplier.phone ? item.supplier.phone : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">อีเมล :</label>
                                <p>{{item.supplier&&item.supplier.email ? item.supplier.email : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ไลน์ :</label>
                                <p>{{item.supplier&&item.supplier.line ? item.supplier.line : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">หมายเหตุ :</label>
                                <p>{{item.supplier&&item.supplier.remark ? item.supplier.remark : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-12">
                              <hr class="my-4">
                            </div>
                            <div class="col-12">
                              <h6 class="heading-small text-muted mb-4">เพิ่มเติม</h6>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">กำลังการผลิต (ต่อวัน) :</label>
                                <p>{{item.supplierContracts&&item.supplierContracts.cond_daily_minimum_capacity ? item.supplierContracts.cond_daily_minimum_capacity : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ยอดสั่งรายเดือน :</label>
                                <p>{{item.supplierContracts&&item.supplierContracts.cond_monthly_minimum_order ? item.supplierContracts.cond_monthly_minimum_order : '-'}}</p>
                              </div>
                            </div>
                            <!-- <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ต้นทุนสินค้า :</label>
                                <p>{{item.supplierContracts&&item.supplier.price ? item.supplier.price : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ต้นทุนจัดส่ง :</label>
                                <p>{{item.supplierContracts&&item.supplier.delivery_price ? item.supplier.delivery_price : '-'}}</p>
                              </div>
                            </div> -->
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="ข้อมูลการชำระเงิน">
                        <b-card-text>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">รหัสการชำระเงิน :</label>
                                <p>{{item.payment&&item.payment.detail&&item.payment.detail.code ? item.payment.detail.code : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">รูปแบบการจ่ายเงิน :</label>
                                <p>{{item.payment&&item.payment.detail&&item.payment.detail.type ? StringUtils.getTextPaymentType(item.payment.detail.type).text : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">หมายเลขอ้างอิง :</label>
                                <p>{{item.payment&&item.payment.detail&&item.payment.detail.ref ? item.payment.detail.ref : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">จำนวนเงินทั้งหมด :</label>
                                <p>{{item.payment&&item.payment&&item.payment.detail.amount ? item.payment.detail.amount : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">สถานะการชำระเงิน :</label>
                                <p>{{item.payment&&item.payment.detail&&item.payment.detail.status ? StringUtils.getTextPaymentStatus(item.payment.detail.status).text: '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">จำนวนเงินที่คงเหลือที่ยังไม่จ่าย :</label>
                                <p>{{item.payment&&item.payment.amount ? item.payment.amount : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">จำนวนเงินที่จ่าย :</label>
                                <p>{{item.payment&&item.payment.paid ? item.payment.paid : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">จำนวนเงินที่คงเหลือ :</label>
                                <p>{{item.payment&&item.payment.remain ? item.payment.remain : '-'}}</p>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="ข้อมูลการจัดส่ง">
                        <b-card-text>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">สถานะการจัดส่ง :</label>
                                <p>{{item.delivery&&item.delivery.status ? StringUtils.getTextDeliveriesStatus(item.delivery.status).text : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">วันที่จัดส่งที่กำหนด :</label>
                                <p>{{item.delivery&&item.delivery.expected_delivery_date ? item.delivery.expected_delivery_date : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">เวลาจัดส่งที่กำหนด :</label>
                                <p>{{item.delivery&&item.delivery.expected_delivery_time ? item.delivery.expected_delivery_time : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">วันที่จัดส่งจริง :</label>
                                <p>{{item.delivery&&item.delivery.actual_delivery_date ? item.delivery.actual_delivery_date : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">เวลาจัดส่งจริง :</label>
                                <p>{{item.delivery&&item.delivery.actual_delivery_time ? item.delivery.actual_delivery_time : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-12">
                              <hr class="my-4">
                            </div>
                            <div class="col-12">
                              <h6 class="heading-small text-muted mb-4">รูปก่อนส่ง</h6>
                            </div>
                            <div class="col-12">
                              <img v-for="(img, index) in item.delivery.photo_before" :key="`list_photo_before_${index}`" :src="img.url" class="img-thumbnail" style="max-width: 175px;" @click="uploadFileHandler()">
                            </div>
                            <div class="col-12 mt-5">
                              <h6 class="heading-small text-muted mb-4">รูปหลังส่ง</h6>
                            </div>
                            <div class="col-12">
                              <img v-for="(img, index) in item.delivery.photo_after" :key="`list_photo_after_${index}`" :src="img.url" class="img-thumbnail" style="max-width: 175px;" @click="uploadFileHandler()">
                            </div>
                            <div class="col-12">
                              <hr class="my-4">
                            </div>
                            <div class="col-12">
                              <h6 class="heading-small text-muted mb-4">เพิ่มเติม</h6>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">ความคิดเห็น :</label>
                                <p>{{item.delivery&&item.delivery.comment ? item.delivery.comment : '-'}}</p>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-3">
                                <label class="form-label" style="font-weight: bold;">หมายเหตุ :</label>
                                <p>{{item.delivery&&item.delivery.remark ? item.delivery.remark : '-'}}</p>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="ข้อมูลปัญหา">
                        <b-card-text>
                          <div class="row">
                            <div class="col-12">
                              <div class="table-responsive padding_top_20">
                                <table class="table align-middle table-bordered">
                                  <thead>
                                    <tr class="text-center" >
                                      <th style="width: 80px">ลำดับ</th>
                                      <th>ประเภทปัญหา</th>
                                      <th>หัวข้อ</th>
                                      <th>ความสำคัญ</th>
                                      <th>หมายเหตุ</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(data, index) in item.issues" :key="`list_order_view_issues_${index}`">
                                      <td class="text-center">{{index+1}}</td>
                                      <td class= "text-center">{{data.issue_type_name}}</td>
                                      <td>{{data.topic?data.topic:'-'}}</td>
                                      <td class="text-center">{{StringUtils.getTextIssuePriority(data.priority).text}}</td>
                                      <td>{{data.remark?data.remark:'-'}}</td>
                                    </tr>
                                    <tr v-if="item.issues.length === 0" class="text-center">
                                      <td colspan="5">ไม่พบปัญหา</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </div>
              </div>
            </div>
            <div class="float-right no_print">
              <span><router-link :to="'/evaluate'" class="nav-link text-underline"> กลับ</router-link></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StringUtils from '../../../util/StringUtils';
import DateUtils from '../../../util/dateUtils';
import NumberUtils from '../../../util/numberUtils';
  export default {
    name: 'evaluate-view-page-order-detail',
    data() {
      return {
        permission:{
          appSlug: 'evaluate',
          actionData: {}
        },
        StringUtils: StringUtils,
        id: '',
        item: {
          products: [],
          store: null,
          channel: null,
          customer: {},
          supplier: null,
          payment: null,
          delivery: {
            photo_before: [],
            photo_after: []
          },
          issues: []
        },
        orders: []
      };
    },
    methods: {
      async getOrderDetail (id) {
        this.item = this.orders.find((row) => {
          return row.id == id;
        })
        console.log('item ', this.item)
        this.item.order = {...this.item};
      },
      getLabelUpsale (upsale) {
        if (upsale) {
          return 'ใช่';
        }
        return 'ไม่ใช่';
      },
      clickPrint () {
        window.print();
      },
      async getOrder () {
        this.SpinnerService.showLoader();
        const result = await this.HttpServices.getData(`/ab_evaluate/${this.id}`);
        if(result&&result.status.code=="200"){
          this.item = result.data;

          // this.service = this.item.service;
          if(this.item.date_order){
            this.item.date_order = DateUtils.dateFormat(new Date(this.item.date_order), "DD/MM/YYYY");
          }

          if(this.item.customer){
            this.item.customer.credit_line = this.item.customer.credit_line?NumberUtils.toDecimalFormat(this.item.customer.credit_line):'';
          }
          if(this.item.delivery){
            if(this.item.delivery.expected_delivery_date&&this.item.delivery.expected_delivery_time){
              const tmps = this.item.expected_delivery_time.split(":");
              const d = new Date(this.item.delivery.expected_delivery_date);
              d.setHours(tmps[0]);
              d.setMinutes(tmps[1]);
              this.item.delivery.expected_delivery_time = DateUtils.dateFormat(d, "HH:mm");
            }
            this.item.delivery.expected_delivery_date = this.item.delivery.expected_delivery_date?DateUtils.dateFormat(new Date(this.item.delivery.expected_delivery_date), "DD/MM/YYYY"):'';

            if(this.item.delivery.actual_delivery_date&&this.item.delivery.actual_delivery_time){
              const tmps = this.item.delivery.actual_delivery_time.split(":");
              const d = new Date(this.item.delivery.actual_delivery_date);
              d.setHours(tmps[0]);
              d.setMinutes(tmps[1]);
              this.item.delivery.actual_delivery_time = DateUtils.dateFormat(d, "HH:mm");
            }
            this.item.delivery.actual_delivery_date = this.item.delivery.actual_delivery_date?DateUtils.dateFormat(new Date(this.item.delivery.actual_delivery_date), "DD/MM/YYYY"):'';
            if(this.item.delivery.photo_before){
              this.item.delivery.photo_before = JSON.parse(this.item.delivery.photo_before);
            }
            if(this.item.delivery.photo_after){
              this.item.delivery.photo_after = JSON.parse(this.item.delivery.photo_after);
            }
          }else{
            this.item.delivery = {
              photo_before: [],
              photo_after: []
            };
          }
          if(this.item.payment){
            this.item.payment.amount = this.item.payment.amount?NumberUtils.toDecimalFormat(this.item.payment.amount):'';
            this.item.payment.paid = this.item.payment.paid?NumberUtils.toDecimalFormat(this.item.payment.paid):'';
            this.item.payment.remain = this.item.payment.remain?NumberUtils.toDecimalFormat(this.item.payment.remain):'';
            if(this.item.payment.detail){
              this.item.payment.detail.amount = this.item.payment.detail.amount?NumberUtils.toDecimalFormat(this.item.payment.detail.amount):'';
            }
          }
        }else{
          this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูลการสั่งซื้อ`, ()=>{
            this.$router.push('/evaluate');
          });
        }
        this.SpinnerService.hideLoader();
      }
    },
    async mounted () {
      await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
      this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
      this.id = await this.SessionStorageUtils.getSession("page-evaluate");
      await this.getOrder();
    }
  };
</script>

